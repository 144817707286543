<template>
    <div class="sta-wrap">
        <div class="left-wrap">
            <div class="title">
                {{ title }}
            </div>
        </div>
        <div class="right-content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: () => {
                return '测试'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sta-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    // border: 1px solid $col-border-middle;
    border: 1px solid #f7f7f7;
    border-right: 0;
    // border-bottom: 0;
}
.left-wrap {
    width: 70px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 0;
    .title {
        width: 20px;
        font-size: 16px;
        color: #333;
        font-weight: 600;
    }
}
.right-content {
    width: 100%;
    height: 100%;
}
</style>
