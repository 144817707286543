/*
 * @Description: 清洗接口数据，返回理想的数据格式
 * @Author: CZ
 * @Date: 2021-06-23 11:04:30
 * @Editors: CZ
 * @LastEditTime: 2021-07-17 16:58:54
 */
import store from '@/store';
/**
 * @description: 清洗重点园区 + 重点企业格式
 * @param {*} item
 * @return {*}
 */
export function cleanFocus(item) {
    let target = [       // 重点园区 + 重点企业
        {
            label: '扶持企业数',
            num: item.yqnum,
            unit: '家',
        },
        {
            label: '总扶持金额',
            num: item.qynum,
            unit: '万',
        }
    ]
    return target;
}

/**
 * @description: 清洗领导和服企专员
 * @param {*} item
 * @return {*}
 */
export function cleanldDataNumDto(item) {
    let target = [
        {
            label: '市挂点领导',
            num: item.sgdldnum,
            unit: '人',
        },
        {
            label: '区挂点领导',
            num: item.qgdldnum,
            unit: '人',
        },
        {
            label: '区直达领导',
            num: item.qzdldnum,
            unit: '人',
        },
        {
            label: '街道挂点领导',
            num: item.jdgdldnum,
            unit: '人',
        },
        {
            label: '街道直达领导',
            num: item.jdzdldnum,
            unit: '人',
        },
        // {
        //     label: '服企专员',
        //     num: item.fqzynum,
        //     unit: '人',
        // }
    ]
    return target;
}

/**
 * @description: 清洗诉求直达概况
 * @param {*} item
 * @return {*}
 */
 export function cleansqywNumDto(item, time) {
    let title = '累计诉求';
    if( time[0] === time[1] && time[0] !== '') {
        title = '新增诉求'
    }
    let target = [
        {
            label: title,
            num: item.totalsqnum,
            unit: '件',
        },
        {
            label: '办理中',
            num: item.blzsqnum,
            unit: '件',
        },
        {
            label: '已办结',
            num: item.bjsqnum,
            unit: '件',
        },
    ]
    return target;
}

/**
 * @description: 清洗政策直达
 * @param {*} zcxmNumDto        政策项目和推荐次数统计
 * @param {*} zcxmYwNumDtos     政策项目业务统计
 * @return {*}
 */
 export function cleanPolicyDirect({zcxmNumDto, zcxmYwNumDto, time,gjnum,provinceNum,sjnum,xjnum}) {
    // let title = '累计申报';
    // if( time[0] === time[1] && time[0] !== '') {
    //     title = '新增申报'
    // }
    let target = []
    if( zcxmNumDto && zcxmYwNumDto ) {
        target = [
            {
              label: '国家级政策',
              num: gjnum || 0,
              unit: '个',
            },
            {
              label: '省级政策',
              num: provinceNum || 0,
              unit: '个',
            },
            {
              label: '市级政策',
              num: sjnum || 0,
              unit: '个',
            },
            {
              label: '区县政策',
              num: xjnum || 0,
              unit: '个',
            },
            {
                label: '政策项目',
                num: zcxmNumDto.zcxmnum,
                unit: '项',
            },
            // {
            //     label: '推送政策',
            //     num: zcxmNumDto.tscsnum,
            //     unit: '次',
            // },
            // {
            //     label: title,
            //     num: zcxmYwNumDto.ljsbnum,
            //     unit: '件',
            // },
            // {
            //     label: '办理中',
            //     num: zcxmYwNumDto.blznum,
            //     unit: '件',
            // },
            // {
            //     label: '已办结',
            //     num: zcxmYwNumDto.bjnum,
            //     unit: '件',
            // },
        ]
    }
    return target;
}

/**
 * @description: 清洗 政府直达 > 重点企业数据 A B C D 四类
 * @param {*} qyTypeDtos
 * @return {*}
 */
 export function cleanqyTypeDtos(qyTypeDtos) {
    let target = [];
    if( qyTypeDtos ) {
        for( const item of qyTypeDtos ) {
            target.push({
                num: item.num,
                unit: '家',
                label: `${item.typename}类企业`,
                value: item.typename,
                isAction: false,
                url: require(`../assets/images/lb_${item.typename}.png`),
                dx: item.typename.charCodeAt(),
            })
        }
    }
    target.sort( function( a, b ) {
        return a.dx > b.dx ? 0 : -1
    })
    return target;
}

/**
 * @description: 清洗 政府直达 > [ 挂点企业 + 直达领导 ]
 * @param {*}
 * @return {*}
 */
 export function cleangdzdLeader( ldDataNumDto, ldYwNumDto ) {
    let target = [];
    let gdList = {
        leaderHeader: [     // 领导表格标题
            { type: '', label: '', prop: 'label', minWidth: '20%' },
            { type: '', label: '市挂点领导', prop: 'sgdld', minWidth: '25%' },
            { type: '', label: '区挂点领导', prop: 'qgdld', minWidth: '25%' },
            { type: '', label: '街道挂点领导', prop: 'jdgdld', minWidth: '30%' },
        ],
        leaderData: [     // 表格数据
            {  label: '挂点领导' , sgdld: ldDataNumDto.sgdldnum, qgdld: ldDataNumDto.qgdldnum, jdgdld: ldDataNumDto.jdgdldnum },
            {  label: '挂点企业' , sgdld: ldYwNumDto.sgdqynum, qgdld: ldYwNumDto.qgdqynum, jdgdld: ldYwNumDto.jdgdqynum },
            {  label: '诉求业务' , sgdld: ldYwNumDto.sgdldsqywnum, qgdld: ldYwNumDto.qgdldsqywnum, jdgdld: ldYwNumDto.jdgdldsqywnum },
            {  label: '政策业务' , sgdld: ldYwNumDto.sgdldzcywnum, qgdld: ldYwNumDto.qgdldsqywnum, jdgdld: ldYwNumDto.jdgdldzcywnum },
        ]
    }
    let zdList = {
        leaderHeader: [     // 领导表格标题
            { type: '', label: '', prop: 'label', minWidth: '20%' },
            { type: '', label: '区直达领导', prop: 'qzdld', minWidth: '40%' },
            { type: '', label: '街道直达领导', prop: 'jdzdld', minWidth: '40%' },
        ],
        leaderData: [     // 表格数据
            {  label: '直达领导' , qzdld: ldDataNumDto.qzdldnum, jdzdld: ldDataNumDto.jdzdldnum },
            {  label: '直达企业' , qzdld: ldYwNumDto.qzdqynum, jdzdld: ldYwNumDto.jdzdqynum },
            {  label: '诉求业务' , qzdld: ldYwNumDto.qzdldsqywnum, jdzdld: ldYwNumDto.jdzdldsqywnum },
            {  label: '政策业务' , qzdld: ldYwNumDto.qzdldsqywnum, jdzdld: ldYwNumDto.jdzdldzcywnum },
        ]
    }
    target.push( gdList, zdList );
    return target;
}

/* 诉求直达业务 */

/**
 * @description: 清洗 诉求服务热度 > [ 诉求类别 ]
 * @param {*}   sqTypeNumDtos 诉求类别
 * @return {*}
 */
 export function cleansqServiceHeat( sqTypeNumDtos ) {
    let target = [];
    let sqType = {
        leaderHeader: [     // 领导表格标题
            { type: 'index', label: '序号', prop: '', width: 50 },
            { type: '', label: '诉求类别', prop: 'typename', minWidth: '31%' },
            { type: '', label: '累计诉求', prop: 'ljsqnum', minWidth: '23%'},
            { type: '', label: '办理中', prop: 'blznum', minWidth: '23%' },
            { type: '', label: '已办结', prop: 'bjnum', minWidth: '23%' },
        ],
        leaderData: sqTypeNumDtos
    };
    target.push( sqType );
    return target;
}

/**
 * @description: 清洗 诉求企业分析 > [ 企业资质 ]
 * @param {*}   qyzzSqywnumDtos 企业资质
 * @return {*}
 */
 export function cleansqEnterpriseQualification( qyzzSqywnumDtos ) {
    let target = [];
    let qyzz = {
        leaderHeader: [     // 领导表格标题
            { type: 'index', label: '序号', prop: '', width: 50 },
            { type: '', label: '企业资质', prop: 'name', minWidth: '31%' },
            { type: '', label: '累计诉求', prop: 'ljsqnum', minWidth: '23%' },
            { type: '', label: '办理中', prop: 'blznum', minWidth: '23%' },
            { type: '', label: '已办结', prop: 'bjnum', minWidth: '23%' },
        ],
        leaderData: qyzzSqywnumDtos
    };
    target.push(qyzz)
    // console.log("企业资质>>>", target)
    return target;
}

/* 政策直达业务 */
/**
 * @description: 清洗 政策申请热度 > [ 政策项目 ]
 * @param {*}   zcxmYwNumDtos 政策项目
 * @return {*}
 */
 export function cleanzcApplyHeat( zcxmYwNumDtos ) {
    let target = [];
    let zcxm = {
        leaderHeader: [     // 领导表格标题
            { type: 'index', label: '序号', prop: '', width: 50 },
            { type: '', label: '政策项目', prop: 'sxmc', minWidth: '31%' },
            { type: '', label: '累计申报', prop: 'ljsbnum', minWidth: '23%' },
            { type: '', label: '办理中', prop: 'blznum', minWidth: '23%' },
            { type: '', label: '已办结', prop: 'bjnum', minWidth: '23%' },
        ],
        leaderData: zcxmYwNumDtos
    };
    target.push( zcxm );
    return target;
}

/**
 * @description: 清洗 政策直达成效 > [ 发放年度 ]
 * @param {*}   zcZdcxNumDtos 发放年度
 * @return {*}
 */
 export function cleanzcToEffect( zcZdcxNumDtos ) {
    let target = [];
    let ffnd = {            // 发放年度
        leaderHeader: [     // 领导表格标题
            { type: 'index', label: '序号', prop: '', width: 50 },
            { type: '', label: '发放年度', prop: 'year', minWidth: '28%' },
            { type: '', label: '资金(万元)', prop: 'zzje', minWidth: '18%' },
            { type: '', label: '学位(个)', prop: 'xwnum', minWidth: '18%' },
            { type: '', label: '人才房(套)', prop: 'rcfnum', minWidth: '18%' },
            { type: '', label: '人才服务(次)', prop: 'rcfwnum', minWidth: '18%' },
        ],
        leaderData: zcZdcxNumDtos
    };
    target.push( ffnd );
    return target;
}
