/*
 * @Description: 公用的请求存放地址
 * @Author: CZ
 * @Date: 2021-06-23 10:33:55
 * @Editors: CZ
 * @LastEditTime: 2021-07-01 14:38:46
 */
import request from '@/utils/request';
/* com */
// 1. 大屏的初始化统计
// 2. 获取A,B,C,D类企业经纬度

/* 初始化拆开 */

/* 中间板块：右侧统计 */
// 1.重点企业 + 重点园区
// 2.挂点领导与直达领导统计
// 3.诉求业务统计
// 4.政策项目统计 
// 5.政策直达业务
 
/* 右侧板块：政府直达 */
// 6.企业分类统计  
// 7.领导挂点，直达企业业务统计

/* 右侧板块：诉求直达 */
// 8.诉求类别统计业务
// 9.企业资质业务统计

/* 右侧板块：政策直达 */
// 10.政策项目业务统计
// 11.政策直达成效统计

/**
 * @description: 大屏的初始化统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
export function getInitialData(params) {
    return request({
        url: '/dev-api/screen/getData',
        method: 'get',
        params: params
    })
}

/**
 * @description: 获取A,B,C,D类企业经纬度
 * @param { Object } params areacode: 区划编码 pm: A,B,C,D类标识
 * @return {*}
 */
 export function getQyLatLon(params) {
    return request({
        url: '/dev-api/screen/getQySpaceInfo',
        method: 'get',
        params: params
    })
}


/* 中间板块：右侧统计 */
/**
 * @description: 重点企业 + 重点园区
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getZdQyYqNum(params) {
    return request({
        url: '/dev-api/screen/getZdQyYqNum',
        method: 'get',
        params: params
    })
}

/**
 * @description: 挂点领导与直达领导统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getGdldNum(params) {
    return request({
        url: '/dev-api/screen/getGdldNum',
        method: 'get',
        params: params
    })
}
/**
 * @description: 领导挂点，直达企业业务统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getLdgdqynum(params) {
    return request({
        url: '/dev-api/screen/getLdgdqynum',
        method: 'get',
        params: params
    })
}
/**
 * @description: 诉求业务统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getSqywNum(params) {
    return request({
        url: '/dev-api/screen/getSqywNum',
        method: 'get',
        params: params
    })
}

/**
 * @description: 政策项目统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getZcxmNum(params) {
    return request({
        url: '/dev-api/screen/getZcxmNum',
        method: 'get',
        params: params
    })
}

/**
 * @description: 政策直达业务
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getZcxmYwNum(params) {
    return request({
        url: '/dev-api/screen/getZcxmYwNum',
        method: 'get',
        params: params
    })
}

/* 右侧模块 */
/* 右侧模块：政府直达 */
/**
 * @description: 企业分类统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getQyTypeStatics(params) {
    return request({
        url: '/dev-api/screen/getQyTypeStatics',
        method: 'get',
        params: params
    })
}

/* 右侧模块：诉求直达 */
/**
 * @description: 诉求类别统计业务
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getSqTypeNum(params) {
    return request({
        url: '/dev-api/screen/getSqTypeNum',
        method: 'get',
        params: params
    })
}

/**
 * @description: 企业资质业务统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getQyZzSqywNum(params) {
    return request({
        url: '/dev-api/screen/getQyZzSqywNum',
        method: 'get',
        params: params
    })
}

/* 右侧模块：政策直达 */
/**
 * @description: 政策项目业务统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getZcxmYwnumBySxmc(params) {
    return request({
        url: '/dev-api/screen/getZcxmYwnumBySxmc',
        method: 'get',
        params: params
    })
}

/**
 * @description: 政策直达成效统计
 * @param { Object } params areacode: 区划编码 starttime: 开始时间 endtime: 结束时间
 * @return {*}
 */
 export function getZcZdcxByYear(params) {
    return request({
        url: '/dev-api/screen/getZcZdcxByYear',
        method: 'get',
        params: params
    })
}