<!--
 * @Description:
 * @Author: CZ
 * @Date: 2021-06-22 16:53:43
 * @Editors: CZ
 * @LastEditTime: 2021-07-17 17:01:50
-->
<template>
  <div class="right-wrap">
    <div>
      <!-- 系统2021年6月1日上线以来 -->
    </div>
    <div class="shouqi">收起 <i class="el-icon-caret-top"></i></div>
    <div style="background: #fff; padding: 0 10px 10px 10px">
      <!-- 起始时间筛选 -->
      <div class="day-picker">
        <el-date-picker
          value-format="yyyy-MM-dd"
          popper-class="mydatestyle"
          v-model="time"
          type="daterange"
          range-separator="-"
          :start-placeholder="'开始日期'"
          :end-placeholder="'结束日期'"
        >
        </el-date-picker>
      </div>
      <!-- 当前区域 -->
      <!-- <div class="currentArea">
      {{ currentArea.label || "漯河市" }}
    </div> -->
      <!-- 筛选： 累计，日、周、月、年 -->
      <div class="flex-row-between click-time">
        <div
          class="time"
          :class="item.value === currentTimesValue ? 'isItme' : ''"
          v-for="item of times"
          :key="item.value"
          @click="activeTimes(item)"
        >
          {{ item.label }}
        </div>
      </div>
      <!-- 重点园区 + 重点企业 -->
      <!-- <LineOne :showArr="zdQyYqDto" :title="''"></LineOne> -->
      <div class="zdQyYqDto">
        <div v-for="item in zdQyYqDto" :key="item.label" class="zdQyYqDtoCon">
          <div style="color: #3090d5; font-size: 18px">{{ item.label }}</div>
          <div class="num">
            <span style="color: #46de7a; font-size: 24px">{{ item.num }}</span>
            <span style="color: #333">{{ item.unit }}</span>
          </div>
        </div>
      </div>
      <!-- 政府直达 + 诉求直达 + 政策直达 统计 -->
      <!-- <StaWrap :title="'诉求情况'">
      <LineOne
        slot="content"
        :showArr="sqDirect"
        :title="'诉求情况'"
      ></LineOne>
    </StaWrap> -->
      <StaWrap :title="'政策推送'">
        <LineOne
          slot="content"
          :showArr="policyDirect"
          :title="'政策推送'"
        ></LineOne>
      </StaWrap>
      <!-- <StaWrap :title="'政策直达'">
      <LineOne
        slot="content"
        :showArr="policyDirect"
        :title="'政策直达'"
      ></LineOne>
    </StaWrap> -->
    </div>
  </div>
</template>

<script>
import LineOne from "../components/lineOne.vue";
import StaWrap from "../components/staWrap.vue";

import { mapGetters } from "vuex";
import moment from "moment";

export default {
  components: {
    LineOne,
    StaWrap,
  },
  computed: {
    ...mapGetters([
      "currentArea", // 当前选中区域
      "zdQyYqDto", // 重点园区 + 重点企业
      // "ldDataNumDto", // 政府直达
      "sqDirect", // 诉求直达
      "policyDirect", // 政策直达
      // "currentTime",
      // "sqListByType",
      // "qyTypeDtos", // ABCD企业数据
    ]),
  },
  watch: {
    time(newTime, oldTime) {
      if (newTime !== oldTime) {
        // console.log("当前的时间段++++", newTime, oldTime)
        this.$store.commit("upcurrentTime", newTime);
      }
    },
    // currentTime(newTime, oldTime) {
    //   if (newTime !== oldTime && newTime.length !== 0) {
    //     this.handleLineOne();
    //   }
    // },
    // currentArea(newArea, oldArea) {
    //   if (newArea !== oldArea) {
    //     this.handleLineOne();
    //   }
    // },
  },
  data() {
    return {
      policyNumList:[],
      time: [],
      timeFormat: "yyyy-MM-DD",
      indexList: [],
      showLeftWrap: false, // 副框弹窗
      leftWrapTitle: "",
      oneTitle: null, // 一级标题 '' 政府直达 诉求直达 政策直达
      oneItem: null,
      sqParams: {
        // 单独的参数
        blstatus: "", // 办理状态
        bjstatus: "", // 办结状态
        type: "", // 服务类别名称
      },
      zcParams: {
        // 单独的参数
        blstatus: "", // 办理状态
        bjstatus: "", // 办结状态
        type: "", // 服务类别名称
      },
      times: [
        // 时间选择
        {
          label: "累计",
          value: "cumulative",
        },
        {
          label: "日",
          value: "day",
        },
        {
          label: "周",
          value: "weeks",
        },
        {
          label: "月",
          value: "month",
        },
        {
          label: "年",
          value: "years",
        },
      ],
      currentTimesValue: "cumulative", // 当前时间段
      currentApi: [], // 调用的接口
      labelval: "",
      deptname: "",
      pageInfo: {
        pageNum: 1,
        pageSize: 14,
        total: 0,
      },
      /* 假数据 */
      // zdQyYqDto: [
      //   {
      //     "label": "扶持企业数",
      //     "num": 1398,
      //     "unit": "家"
      //   },
      //   {
      //     "label": "总扶持金额",
      //     "num": 9981,
      //     "unit": "万"
      //   }
      // ],
      ldDataNumDto: [
        {
          label: "累计诉求",
          num: 3162,
          unit: "件",
        },
        {
          label: "办理中",
          num: 2,
          unit: "件",
        },
        {
          label: "已办结",
          num: 3160,
          unit: "件",
        },
      ],
      // sqDirect:  [
      //   {
      //     "label": "政策项目",
      //     "num": 142,
      //     "unit": "件"
      //   },
      //   {
      //     "label": "推送政策",
      //     "num": 58,
      //     "unit": "件"
      //   },
      //   {
      //     "label": "累计申报",
      //     "num": 40,
      //     "unit": "件"
      //   },
      //   {
      //     "label": "办理中",
      //     "num": 4,
      //     "unit": "件"
      //   },
      //   {
      //     "label": "已办结",
      //     "num": 36,
      //     "unit": "件"
      //   }
      // ]
    };
  },
  methods: {
    /**
     * @description: 点击每一行
     * @param {*} item 点击的什么
     * @param {*} title 点击的大标题：1. 政府直达 2. 诉求直达 3. 政策直达（* 重点园区，重点企业没有）
     * @return {*}
     */
    clickLineOne(item, title) {
      // 先关闭之前的
      this.closeLeftWrap();
      // console.log("直接点击列表,title>>>", title);
      this.oneTitle = title; // 一级标题
      this.oneItem = item; // 存当前选中
      this.handleLineOne();
    },
    /**
     * @description: 分页切换
     * @param n*o
     * @return n*o
     */
    pageChange(value) {
      this.indexList[this.indexList.length - 1].pageInfo.pageNum = value;
      this.pageInfo.pageNum = value;
      this[this.currentApi[this.currentApi.length - 1]]();
    },
    /**
     * @description: 表格点击事件 > 1 层
     * @param n*o
     * @return n*o
     */
    async cellClickByTable(el) {
      // console.log("接收表格点击事件>>>", el)
      let zfArr = [
        "政府直达市挂点领导",
        "政府直达区挂点领导",
        "政府直达街道挂点领导",
        "政府直达区直达领导",
        "政府直达街道直达领导",
      ];
      let sqArr = ["诉求直达累计诉求", "诉求直达办理中", "诉求直达已办结"];
      let zcArr = [
        "政策直达推送政策",
        "政策直达累计申报",
        "政策直达办理中",
        "政策直达已办结",
      ];
      let len = this.indexList.length; // 标识层级使用
      let title = this.oneTitle + this.leftWrapTitle;
      // console.log("title>>>", title)
      if (zfArr.indexOf(title) !== -1) {
        // 判断是那个板块                政府直达
        switch (
          len // 判断层级
        ) {
          case 1: // 查询企业信息 el.name: 领导名字
            let target = await this.getLeaderGdYwNum(el.user_id);
            this.$emit("showAddPointes", target);
            break;
          case 2: // 转跳到企业画像 el.shxydm 统一社会信用代码
            // console.log("转跳到企业画像详情")
            // let target1 = [];
            // target1.push(el);
            // this.$emit("showInitMap", +el.lon, +el.lat, '6' );    // 定位
            // this.$emit("showAddPointes", target1);                // 描点
            this.$emit("showEnterprise", el.shxydm); // 弹出企业画像
            break;
        }
      } else if (title === "重点企业") {
        if (el && el.total) {
          // 下一步应该是获取企业列表
          this.getZDQYNum(el.labelval);
        } else if (el && el.shxydm) {
          this.$emit("showEnterprise", el.shxydm);
        }
      } else if (sqArr.indexOf(title) !== -1) {
        // 诉求直达
        if (el.typename) {
          // console.log("查询企业对应的诉求")
          this.getQyListBysqType(el.typename);
        } else if (el.code) {
          // console.log("转跳到诉求列表")
          this.getSqListByQy(el.code);
        }
      } else if (zcArr.indexOf(title) !== -1) {
        // 政策直达
        if (el.sxid) {
          // console.log("查询项目类别对应的企业")
          this.getQyListByzcName(el.sxid);
        } else if (el.code) {
          // console.log("政策申报列表")
          this.getZcListByQy(el.code);
        } else if (el.typename) {
          // console.log("查询推送方式对应的政策")
          this.getZcListBytsType(el.type);
        } else if (el.pusherid) {
          // console.log("查询推送人对应的企业")
          this.getQyListBytsName(el.pusherid);
        } else if (el.shxydm) {
          // console.log("政策推送列表")
          this.getZcTsListByQy(el.shxydm);
        }
      }
    },
    /**
     * @description: 点击切换时间段
     * @param {*} item
     * @return {*}
     */
    activeTimes(item) {
      this.currentTimesValue = item.value; //  切换当前时间区间选择
      let timeFormat = this.timeFormat;
      const today = moment().locale("zh-cn").format(timeFormat); // 今天
      let target = []; // 中间数组，用来存选择的时间段
      let startDate = "";
      // console.log("今天", today)
      switch (item.value) {
        case "cumulative":
          target = ["", ""];
          break;
        case "day":
          target = [today, today];
          break;
        case "weeks":
          startDate = moment()
            .week(moment().week())
            .startOf("week")
            .format(timeFormat);
          target = [startDate, today];
          break;
        case "month":
          startDate = moment()
            .month(moment().month())
            .startOf("month")
            .format(timeFormat);
          target = [startDate, today];
          break;
        case "years":
          startDate = moment()
            .year(moment().year())
            .startOf("year")
            .format(timeFormat);
          target = [startDate, today];
          break;
      }
      this.time = target; // 赋值时间段
      // console.log("当前时间段>>>", this.currentTimesValue, target);
    },
    /**
     * @description: 根据企业资质统计企业数量
     * @param { code } 企业资质code  qyzzNumDtos
     * @return n*o
     */
    getQytjByQyzz(code, type) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      if (code) {
        this.atoDNum = code;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        code: this.atoDNum || "", // A B C D 类
        PAGE_CUR: this.pageInfo.pageNum + "",
        PAGE_ROWS: this.pageInfo.pageSize + "",
      };
      getQytjByQyzz(params).then((res) => {
        // console.log("根据企业资质统计企业数量>>>",params, res)
        let pageInfo = {
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          total: res.pageInfo.pageCount,
        };
        let target = {};
        if (res.data) {
          target = cleanLeaderGdYwNum(res.data, pageInfo)[0];
        }
        if (
          this.currentApi[this.currentApi.length - 1] == "getQytjByQyzz" &&
          !type
        ) {
          this.indexList.pop();
        } else {
          this.currentApi.push("getQytjByQyzz");
        }
        this.startAdd(target);
      });
    },
    /**
     * @description: 获取领导对应的挂点企业
     * @param {*} areacode  区划编码
     * @param {*} leaderid  领导用户id
     * @param {*} pm  A,B,C,D类标识
     * @param {*} qyzzcode  企业资质code
     * @return n*o
     */
    async getLeaderGdYwNum(leaderid, qyzzcode) {
      return new Promise((resolve) => {
        let areacode = this.currentArea.id
          ? this.currentArea.id.toString()
          : "";
        let areacodeLen = areacode.length;
        let communitycode = "";
        if (areacodeLen === 6) {
          // 区级
          areacode = "";
        } else if (areacodeLen === 12) {
          // 社区级
          areacode = "";
          communitycode = this.currentArea.id;
        }
        let params = {
          areacode: areacode, // 区划编码
          starttime: this.currentTime[0] || "",
          communitycode: communitycode,
          endtime: this.currentTime[1] || "",
          leaderid: leaderid || "",
          qyzzcode: qyzzcode || "",
        };
        getLeaderGdYwNum(params).then((res) => {
          // console.log("获取领导对应的挂点企业>>>", res)
          let target = {};
          if (res.length > 0) {
            target = cleanQyList(res)[0];
          }
          this.startAdd(target);
          resolve(res);
        });
      });
    },
    /**
     * @description: 获取重点园区企业列表查询
     * @return n*o
     */
    getZDQYNum(labelval) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      if (labelval) {
        this.labelval = labelval;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        labelval: this.labelval || "",
        sort: this.atoDNum || "",
        PAGE_CUR: this.pageInfo.pageNum + "",
        PAGE_ROWS: this.pageInfo.pageSize + "",
      };
      getZDQYNum(params).then((res) => {
        // console.log("获取重点园区企业列表查询>>>",params, res)
        let pageInfo = {
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          total: res.pageInfo.pageCount,
        };
        let target = {};
        if (res.data) {
          target = cleanQyList(res.data, pageInfo)[0];
        }
        if (this.currentApi[this.currentApi.length - 1] == "getZDQYNum") {
          this.indexList.pop();
        } else {
          this.currentApi.push("getZDQYNum");
        }
        this.startAdd(target);
      });
    },
    /**
     * @description: 获取挂点企业
     * @param {*} type 1 2 3
     * @return {*}
     */
    getGdEnterprise(type) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      if (type) {
        this.labelval = type;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        gdld_type: this.labelval,
        PAGE_CUR: this.pageInfo.pageNum + "",
        PAGE_ROWS: this.pageInfo.pageSize + "",
      };
      getGdEnterprise(params).then((res) => {
        // console.log("获取挂点企业>>>",params, res)
        let pageInfo = {
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          total: res.pageInfo.pageCount,
        };
        let target = {};
        if (res.data) {
          target = cleanZfGdleader(res.data, pageInfo)[0];
        }
        if (this.currentApi[this.currentApi.length - 1] == "getGdEnterprise") {
          this.indexList.pop();
        } else {
          this.currentApi.push("getGdEnterprise");
        }
        this.startAdd(target);
      });
    },
    /**
     * @description: 获取直达企业
     * @param {*} type 1 2 3
     * @return {*}
     */
    getZdEnterprise(type) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      if (type) {
        this.labelval = type;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        gdld_type: this.labelval,
        PAGE_CUR: this.pageInfo.pageNum + "",
        PAGE_ROWS: this.pageInfo.pageSize + "",
      };
      getZdEnterprise(params).then((res) => {
        // console.log("获取直达企业>>>",params, res)
        let pageInfo = {
          pageNum: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          total: res.pageInfo.pageCount,
        };
        let target = {};
        if (res.data) {
          target = cleanZfZdleader(res.data, pageInfo)[0];
        }
        if (this.currentApi[this.currentApi.length - 1] == "getZdEnterprise") {
          this.indexList.pop();
        } else {
          this.currentApi.push("getZdEnterprise");
        }
        this.startAdd(target);
      });
    },
    /**
     * @description: 诉求业务按部门搜索（支持区划查询）
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @return {*}
     */
    async getSqListByType(blstatus, bjstatus) {
      return new Promise((resolve) => {
        let areacode = this.currentArea.id
          ? this.currentArea.id.toString()
          : "";
        let areacodeLen = areacode.length;
        let communitycode = "";
        if (areacodeLen === 6) {
          // 区级
          areacode = "";
        } else if (areacodeLen === 12) {
          // 社区级
          areacode = "";
          communitycode = this.currentArea.id;
        }
        let params = {
          areacode: areacode, // 区划编码
          communitycode: communitycode,
          starttime: this.currentTime[0] || "",
          endtime: this.currentTime[1] || "",
          blstatus: blstatus || "",
          bjstatus: bjstatus || "",
        };
        getSqListByType(params).then((res) => {
          console.log("诉求业务按部门搜索>>>", res);
          let target;
          target = cleanSqListByType(res.data);
          this.$store.commit("upsqListByType", target);
          resolve("成功");
        });
      });
    },
    /**
     * @description:  诉求类别统计业务
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @param {*} blstatus 传 0 办理中
     * @param {*} bjstatus 传 1 办结
     * @return {*}
     */
    getSqLBYWnum(blstatus, bjstatus, deptname) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        blstatus: blstatus || "",
        bjstatus: bjstatus || "",
        deptname: deptname || "",
      };
      if (deptname) {
        this.deptname = deptname;
      }
      getSqLBYWnum(params).then((res) => {
        let target = [];
        target = cleanSqLBYWnum(res.sqTypeNumDtos)[0];
        this.startAdd(target);
      });
    },
    /**
     * @description:  诉求企业分析-全部企业
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @param {*} blstatus 传 0 办理中
     * @param {*} bjstatus 传 1 办结
     * @param {*} fwlbname 传诉求类别名称
     * @return {*}
     */
    getQyListBysqType(fwlbname) {
      let blstatus = "";
      let bjstatus = "";
      this.sqParams.type = fwlbname;
      switch (this.leftWrapTitle) {
        case "办理中":
          blstatus = "0";
          this.sqParams.blstatus = blstatus;
          this.sqParams.bjstatus = "";
          break;
        case "已办结":
          bjstatus = "1";
          this.sqParams.bjstatus = bjstatus;
          this.sqParams.blstatus = "";
          break;
      }
      if (fwlbname) {
        this.labelval = fwlbname;
      }
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        blstatus: blstatus || "",
        bjstatus: bjstatus || "",
        deptname: this.deptname,
        fwlbname: this.labelval || "",
        PAGE_CUR: this.pageInfo.pageNum + "",
        PAGE_ROWS: 9 + "",
      };
      getQyListBysqType(params).then((res) => {
        // console.log('诉求企业分析-全部企业',params, res)
        let pageInfo = {
          pageNum: this.pageInfo.pageNum,
          pageSize: 9,
          total: res.pageInfo.pageCount,
        };
        let target = {};
        if (res.data) {
          target = cleanQyListBysqType(
            res.data,
            this.leftWrapTitle,
            pageInfo
          )[0];
        }
        if (
          this.currentApi[this.currentApi.length - 1] == "getQyListBysqType"
        ) {
          this.indexList.pop();
        } else {
          this.currentApi.push("getQyListBysqType");
        }
        this.startAdd(target);
      });
    },
    /**
     * @description:  政策企业分析-全部企业
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @param {*} blstatus 传 0 办理中
     * @param {*} bjstatus 传 1 办结
     * @param {*} fwzhixcode 传政策id
     * @return {*}
     */
    getQyListByzcName(fwzhixcode) {
      let blstatus = "";
      let bjstatus = "";
      this.zcParams.type = fwzhixcode;
      switch (this.leftWrapTitle) {
        case "办理中":
          blstatus = "0";
          this.zcParams.blstatus = blstatus;
          this.zcParams.bjstatus = "";
          break;
        case "已办结":
          bjstatus = "1";
          this.zcParams.bjstatus = bjstatus;
          this.zcParams.blstatus = "";
          break;
      }
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      if (fwzhixcode) {
        this.labelval = fwzhixcode;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        blstatus: blstatus || "",
        bjstatus: bjstatus || "",
        deptid: this.deptname,
        fwzhixcode: this.labelval || "",
        PAGE_CUR: this.pageInfo.pageNum + "",
        PAGE_ROWS: 9 + "",
      };
      getQyListByzcName(params).then((res) => {
        // console.log('政策企业分析-全部企业',params, res)
        let pageInfo = {
          pageNum: this.pageInfo.pageNum,
          pageSize: 9,
          total: res.pageInfo.pageCount,
        };
        let target = {};
        if (res.data) {
          target = cleanQyListByzcName(
            res.data,
            this.leftWrapTitle,
            pageInfo
          )[0];
        }
        if (
          this.currentApi[this.currentApi.length - 1] == "getQyListByzcName"
        ) {
          this.indexList.pop();
        } else {
          this.currentApi.push("getQyListByzcName");
        }
        this.startAdd(target);
      });
    },
    /**
     * @description:  推送方式-推送政策
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @param {*} tstype 推送类型
     * @return {*}
     */
    getZcListBytsType(tstype) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      this.zcParams.type = tstype;
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        tstype: tstype,
      };
      getZcListBytsType(params).then((res) => {
        // console.log("推送方式-推送政策", res)
        let target = [];
        target = cleanZctsNumByType(res.zctsNumDtos)[0];
        this.startAdd(target);
      });
    },
    /**
     * @description:  推送人-全部企业
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @param {*} pusherid 推送人id
     * @return {*}
     */
    getQyListBytsName(pusherid) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        pusherid: pusherid,
        tstype: this.zcParams.type || "",
      };
      getQyListBytsName(params).then((res) => {
        // console.log("推送人-全部企业", res)
        let target = [];
        target = cleanZctsNumByPush(res.zctsNumDtos)[0];
        this.startAdd(target);
      });
    },
    /**
     * @description: 诉求列表获取-通过tyshxydm查询
     * @param {*} params
     * @return {*}
     */
    getSqListByQy(code) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        tyshxydm: code,
        deptname: this.deptname,
        blstatus: this.sqParams.blstatus || "", // 办理状态
        bjstatus: this.sqParams.bjstatus || "", // 办结状态
        type: this.sqParams.type || "", // 服务类别名称
      };
      getSqListByQy(params).then((res) => {
        // console.log("诉求列表获取-通过tyshxydm查询", res)
        let target = [];
        // target = cleanNumByZcxm(res.zcDtos)[0]
        // this.startAdd(target);
        if (res.data.length > 0) {
          target.type = "sqList";
          target.data = res.data;
        }
        this.startAdd(target);
      });
    },
    /**
     * @description: 政策列表获取-通过tyshxydm查询
     * @param {*} params
     * @return {*}
     */
    getZcListByQy(code) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        shxydm: code,
        blstatus: this.zcParams.blstatus || "", // 办理状态
        bjstatus: this.zcParams.bjstatus || "", // 办结状态
        zcid: this.zcParams.type || "", // 服务类别名称
      };
      getZcListByQy(params).then((res) => {
        // console.log("政策列表获取-通过tyshxydm查询", res)
        let target = [];
        if (res.zcywNumDtos) {
          target.type = "zcList";
          target.data = res.zcywNumDtos;
        }
        this.startAdd(target);
      });
    },
    /**
     * @description: 政策推送列表获取-通过tyshxydm查询
     * @param {*} params
     * @return {*}
     */
    getZcTsListByQy(code) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        shxydm: code,
        blstatus: this.zcParams.blstatus || "", // 办理状态
        bjstatus: this.zcParams.bjstatus || "", // 办结状态
        tstype: this.zcParams.type || "",
      };
      getZcTsListByQy(params).then((res) => {
        console.log("政策推送列表获取-通过tyshxydm查询", res);
        let target = [];
        if (res.zctsDtos) {
          target.type = "zcList";
          target.data = res.zctsDtos;
        }
        this.startAdd(target);
      });
    },
    /**
     * @description:  根据政策统计项目数量
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @return {*}
     */
    getNumByZcxm() {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
      };
      getNumByZcxm(params).then((res) => {
        // console.log("根据政策统计项目数量", res)
        let target = [];
        target = cleanNumByZcxm(res.zcDtos)[0];
        this.startAdd(target);
      });
    },
    /**
     * @description:  推送政策按推送方式统计
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @return {*}
     */
    getTszctj() {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
      };
      getTszctj(params).then((res) => {
        // console.log("推送政策按推送方式统计", res)
        let target = [];
        target = cleanTszctj(res.zctsNumDtos)[0];
        this.startAdd(target);
      });
    },
    /**
     * @description:  政策项目业务统计
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @return {*}
     */
    getZcxmYwnumBySxmc(blstatus, bjstatus, deptid) {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      let areacodeLen = areacode.length;
      let communitycode = "";
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      } else if (areacodeLen === 12) {
        // 社区级
        areacode = "";
        communitycode = this.currentArea.id;
      }
      if (deptid) {
        this.deptname = deptid;
      }
      let params = {
        areacode: areacode, // 区划编码
        communitycode: communitycode,
        starttime: this.currentTime[0] || "",
        endtime: this.currentTime[1] || "",
        blstatus: blstatus || "",
        bjstatus: bjstatus || "",
        deptid: deptid || "",
      };
      getZcxmYwnumBySxmc(params).then((res) => {
        // console.log("政策项目业务统计", res)
        let target = [];
        target = cleanZcxmYwnumBySxmc(res.zcxmYwNumDtos)[0];
        this.startAdd(target);
      });
    },
    /**
     * @description: 按部门统计事项数（支持区划查询）
     * @param {*} areacode  区划编码
     * @param {*} starttime  开始时间
     * @param {*} endtime  结束时间
     * @return {*}
     */
    async getZcDeptNum(blstatus, bjstatus) {
      return new Promise((resolve) => {
        let areacode = this.currentArea.id
          ? this.currentArea.id.toString()
          : "";
        let areacodeLen = areacode.length;
        let communitycode = "";
        if (areacodeLen === 6) {
          // 区级
          areacode = "";
        } else if (areacodeLen === 12) {
          // 社区级
          areacode = "";
          communitycode = this.currentArea.id;
        }
        let params = {
          areacode: areacode, // 区划编码
          communitycode: communitycode,
          starttime: this.currentTime[0] || "",
          endtime: this.currentTime[1] || "",
          blstatus: blstatus || "",
          bjstatus: bjstatus || "",
        };
        getZcDeptNum(params).then((res) => {
          // console.log("按部门统计事项数", res)
          let target;
          target = cleanZcDeptNum(res.zcYwBmDtos);
          // console.log("按部门统计事项数", target)
          this.$store.commit("upZcDeptNum", target);
          resolve("成功");
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/* 正常-wrap */
.right-wrap {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  // background: rgba($col-theme, 1);
  .shouqi {
    align-self: flex-end;
    position: relative;
    width: 56%;
    height: 36px;
    line-height: 36px;
    color: #3d91ff;
    font-weight: 800;
    text-align: center;
    // background: rgba($col-theme, .7);
    background: #fff;
    margin-bottom: -9px;
  }
  .shouqi::before {
    position: absolute;
    right: 157px;
    top: -1px;
    content: "";
    width: 0;
    height: 0;
    border: 18px solid;
    border-color: transparent transparent #fff #fff;
    transform: rotate(-90deg);
  }
}

/* 左边弹出框 */
.left-absolute {
  padding: 5px;
  position: absolute;
  background: rgba($col-theme, 0.7);
  left: -2px;
  top: 0;
  transform: translateX(-100%);
  min-width: 340px;
}
.content {
  min-height: 100px;
}
.content-title {
  text-align: center;
  padding: 4px 0;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 2px;
}
/* 正常-content */
.day-picker {
  width: 100%;
  // padding: 4px;
  margin-bottom: -1px;
}

::v-deep .el-input__inner {
  width: 100%;
  border-radius: 0;
  // border: 1px solid $col-border-middle;
  background: transparent;
  border: none;
}
::v-deep .el-date-editor {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
::v-deep .el-range-input {
  width: 100%;
  background: transparent;
  // color: $col-border-middle;
  color: #333;
  font-size: 16px;
}
::v-deep .el-range-separator {
  color: #333;
}
/* 当前区域 */
.currentArea {
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border: 1px solid $col-border-middle;
}
/* 重点园区 + 重点企业 */

/* 无限框 */
.infinite-frame {
  width: 100%;
}
.icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
}
/* 年月日 */
.click-time {
  width: 100%;
  height: 100%;
  margin-top: 10px;
  // border: 1px solid $col-border-middle;
  // border-top: 0;
  // border-right: 0;
  font-size: 18px;
  .time {
    flex: 1;
    padding: 6px;
    text-align: center;
    height: 100%;
    // border-right: 1px solid $col-border-middle;
    cursor: pointer;
    color: #333;
    border: 1px solid #ebebeb;
    border-right: 0;
    &:hover {
      // background: #02a7f0;
    }
  }
  .time:first-child {
    border-radius: 20px 0 0 20px;
  }
  .time:last-child {
    border-radius: 0 20px 20px 0;
    border-right: 1px solid #ebebeb;
  }
  .isItme {
    font-weight: 700;
    background: #02a7f0;
    color: #fff;
  }
}
.zdQyYqDto {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .zdQyYqDtoCon {
    border-right: 1px solid #ebebeb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 18px;
    height: 40px;
  }
  .zdQyYqDtoCon:last-child {
    border-right: 0;
  }
  .num span {
    font-size: 18px;
    font-family: "lcd-7";
  }
}
.mydatestyle {
  color: #f00;
}
</style>
