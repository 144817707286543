<!--
 * @Description: 一行展示： 名称 + 展示数字
 * @Author: CZ
 * @Date: 2021-06-23 09:28:43
 * @Editors: CZ
 * @LastEditTime: 2021-07-08 11:12:00
-->
<template>
    <div class="line-wrap">
        <div
        class="line-item"
        v-for="(item, index) in showArr" :key="index"
        :style=" index === 0 ? 'border-top: 0' : ''"
        @click="clickLineOne(item)"
        >
            <div class="left">
                {{ item.label }}
            </div>
            <div class="right">
                <span>{{ item.num }}</span>{{ item.unit }}
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'LineOne',
    props: {
        showArr: {
            type: Array,
            default: () => {
                return [
                    {
                        label: '测试',
                        num: '999',
                        unit: '个'
                    }
                ]
            }
        },
        title: {
            type: String,
            default: () => {
                return ''
            }
        }
    },
    methods: {
        /**
         * @description: 单行点击方法，传给父组件item
         * @param {*} item
         * @return {*}
         */
        clickLineOne( item ) {
            this.$emit("clickLineOne", item, this.title)
        }
    }
}
</script>

<style lang="scss" scoped>
.line-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
}
.line-item {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 4px 14px;
    // border: 1px solid $col-border-middle;
    border: 1px solid #f7f7f7;
    border-bottom: 0;
    color:#a3a3a3;
    &:hover {
        // background: $col-border;
    }
}
.left,
.right {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.right {
    span {
        font-size: 24px;
        font-family: 'lcd-7';
        color: $col-yellow;
        padding-right: 4px;
    }
}
</style>
