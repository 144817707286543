/*
 * @Author: your name
 * @Date: 2021-06-26 09:35:46
 * @LastEditTime: 2021-07-17 16:27:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \bigScreen\src\utils\request.js
 */
import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'

let baseurl = process.env.VUE_APP_DATA_URL;

// create an axios instance
const service = axios.create({
  baseURL: baseurl, // url = base url + request url
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    config.headers['appid'] = 'afc903d9d5e449488a3cf9a00c3d1294';
    config.headers['appkey'] = '8cb4f61b-e89a-405b-acc8-4d992826c247';
    if (store.getters.token) {
      // let each request carry token
      // config.headers['X-Token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    return res
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: '某些请求遇到了阻碍',
      type: 'error',
      duration: 2 * 1000
    })
    return Promise.reject(error)
  }
)

export default service

export {
  baseurl,
}
