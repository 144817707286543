<template>
  <div class="banner-limit">
    <div class="analysis-wrap">
      <!-- 地图 -->
      <div class="middleIndex">
        <div id="map"></div>
        <div class="mask"></div>
        <div class="pop" ref="pop" v-if="map">
          <div v-for="(element, index) in clickItem" :key="index">
            <div class="pop-row">
              <div class="">
                {{ element.name + "：" }}
              </div>
              <div class="pop-value">{{ element.value }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 左边 -->
      <div class="map-left">
        <!-- 搜索 -->
        <div class="map-search">
          <!-- <div class="search-input-wrap rect" @click="selectArea(returnCity)">
            <input
              class="search-input"
              v-model="searchInput"
              placeholder="请输入区域名称"
              disabled
            />
          </div> -->
          <div class="shouqi">收起 <i class="el-icon-caret-top"></i></div>
        </div>
        <div style="background: #fff; padding: 0 10px 10px 10px">
          <!-- 区域选择 -->
          <div class="left-area">
            <!-- <div class="area-title">收起</div> -->
            <div class="area-name" @click="getAllList('510600000000')">
              德阳市
            </div>
            <div class="area-list">区域列表</div>
            <div class="area-content">
              <!-- <Breadcrumb
                    @selectBreadcrumb="selectBreadcrumb"
                    :showList="breadcrumbList"
                  >
                  </Breadcrumb> -->
              <el-row class="grid-wrap" :gutter="20">
                <el-col
                  :span="8"
                  v-for="(el, idx) in breadcrumbList[0].children"
                  :key="idx"
                >
                  <div
                    class="grid-content"
                    :class="el.isAction ? 'grid-isAction' : ''"
                    @click="selectArea(el)"
                  >
                    {{ el.label }}
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <!-- 联动展示 -->
          <!-- rect 四角边框样式 -->
          <div class="tracing-wrap">
            <div class="tracing-content">
              <div
                class="flex-row flex-around wd-100 tracing-item tracing-title"
              >
                <div class="flex-2 left-title" style="background: #f9f9f9">
                  {{ tracingShow.leftLabel }}
                </div>
                <div class="flex-1 right-title" style="background: #f9f9f9">
                  {{ tracingShow.rightLabel }}
                </div>
              </div>
              <!-- 内容 -->
              <div
                class="flex-row flex-around wd-100 tracing-item"
                v-for="(item, index) in showTracingArr"
                :key="index"
              >
                <!-- left -->
                <div class="flex-row flex-around flex-2 left-item">
                  {{ item.dictLabel }}
                </div>
                <!-- right -->
                <div class="flex-row flex-around flex-1 right-item">
                  {{ item.num }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右边 -->
      <div class="map-right">
        <RightWrap> </RightWrap>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { mapGetters } from "vuex";
// import { Breadcrumb } from "@/components/breadcrumb/index.js";
import RightWrap from "./right/index.vue";
import { getAreaTree } from "@/api/area.js";
import {
  getInitialData, // 初始化接口
  getQyLatLon, // 获取A,B,C,D类企业经纬度
  getZdQyYqNum, // 重点企业 + 重点园区
  getGdldNum, // 政府直达（挂点领导+直达领导统计）
  getSqywNum, // 诉求直达
  getZcxmNum, // 政策项目统计
  getZcxmYwNum, // 政策直达业务
  getLdgdqynum, // 挂点领导业务
  getSqTypeNum, // 诉求类别统计业务
  getQyZzSqywNum, // 诉求企业分析
  getZcxmYwnumBySxmc, // 政策申请热度
  getZcZdcxByYear, // 政策直达成效
  getQyTypeStatics, // 企业统计 A B C D
} from "@/api/com.js";
import {
  cleanFocus,
  cleanldDataNumDto,
  cleansqywNumDto,
  cleanPolicyDirect,
  cleanqyTypeDtos,
  cleangdzdLeader, // 清洗 政府直达 > [ 挂点企业 + 直达领导 ]
  cleansqServiceHeat, // 清洗 诉求服务热度 > 诉求类别
  cleansqEnterpriseQualification, // 清洗 诉求企业分析 > 企业资质
  cleanzcApplyHeat, // 清洗 政策申请热度 > 政策项目
  cleanzcToEffect, // 清洗 政策直达成效 > [ 发放年度 ]
} from "@/utils/cleanData.js";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  name: "aMap",
  components: {
    // Breadcrumb,
    RightWrap,
  },
  data() {
    return {
      circleTip: [
        { title: "志愿者", type: "0", image: "circle1" },
        { title: "工单", type: "1", image: "circle2" },
      ],
      markerStyles: null,
      massMarks: null,
      tableData: [],
      clickItem: [],
      markDatas: null,
      infoWindow: null,
      map: null,
      mapUI: null,
      zoomMax: 10,
      searchInput: "德阳市", // 搜索输入的值
      showAreaArr: {}, // 当前选中区域的数据
      returnCity: {
        label: "德阳市",
        lat: 104.398651,
        lon: 31.127991,
        id: 510600,
        qhid: 5106000000000,
        isAction: false,
      },
      areaList: [
        {
          label: "德阳市",
          lat: 104.398651,
          lon: 31.127991,
          id: 510600,
          qhid: 5106000000000,
          children: [
            {
              label: "旌阳区",
              isAction: false,
              lat: 104.39367,
              lon: 31.13906,
              id: 510603,
              qhid: 510603000000,
            },
            {
              label: "罗江区",
              isAction: false,
              lat: 104.51025,
              lon: 31.31665,
              id: 510604,
              qhid: 510604000000,
            },
            {
              label: "中江县",
              isAction: false,
              lat: 104.67861,
              lon: 31.03297,
              id: 510623,
              qhid: 510623000000,
            },
            {
              label: "广汉市",
              isAction: false,
              lat: 104.28234,
              lon: 30.97686,
              id: 510681,
              qhid: 510681000000,
            },
            {
              label: "什邡市",
              isAction: false,
              lat: 104.16754,
              lon: 31.1264,
              id: 510682,
              qhid: 510682000000,
            },
            {
              label: "绵竹市",
              isAction: false,
              lat: 104.22076,
              lon: 31.33772,
              id: 510683,
              qhid: 510683000000,
            },
          ],
        },
      ], // 所有的区域信息树
      breadcrumbList: [
        {
          label: "德阳市",
          lat: 104.398651,
          lon: 31.127991,
          id: 510600,
          qhid: 5106000000000,
          children: [
            {
              label: "旌阳区",
              isAction: false,
              lat: 104.39367,
              lon: 31.13906,
              id: 510603,
              qhid: 510603000000,
            },
            {
              label: "罗江区",
              isAction: false,
              lat: 104.51025,
              lon: 31.31665,
              id: 510604,
              qhid: 510604000000,
            },
            {
              label: "中江县",
              isAction: false,
              lat: 104.67861,
              lon: 31.03297,
              id: 510623,
              qhid: 510623000000,
            },
            {
              label: "广汉市",
              isAction: false,
              lat: 104.28234,
              lon: 30.97686,
              id: 510681,
              qhid: 510681000000,
            },
            {
              label: "什邡市",
              isAction: false,
              lat: 104.16754,
              lon: 31.1264,
              id: 510682,
              qhid: 510682000000,
            },
            {
              label: "绵竹市",
              isAction: false,
              lat: 104.22076,
              lon: 31.33772,
              id: 510683,
              qhid: 510683000000,
            },
            {
              label: "凯州新城",
              isAction: false,
              lat: 104.60346,
              lon: 30.87034,
              id: 510662,
              qhid: 510662000000,
            },
            {
              label: "经济技术开发区",
              isAction: false,
              lat: 104.41657,
              lon: 31.10761,
              id: 510699,
              qhid: 510699000000,
            },
          ],
        },
      ], // 面包屑数据
      chilrdList: [], // 当前面包屑下面的数据
      showTracing: false, // 是否显示带打点的框
      tracingShow: {
        // 小副标题
        leftLabel: "类别",
        rightLabel: "数量",
      },
      showTracingArr: [], // 显示的值
      nowArea: 5106000000000, //当前选中区划
    };
  },
  watch: {
    currentTime(newTime, oldTime) {
      if (newTime !== oldTime && newTime.length !== 0) {
        this.getInitialData();
      }
    },
    currentArea(newArea, oldArea) {
      if (newArea !== oldArea && newArea.length !== 0) {
        this.getInitialData();
      }
    },
  },
  computed: {
    ...mapGetters([
      "currentArea", // 当前选中的区域
      "currentTime", // 当前选择的时间段
    ]),
  },
  created() {
    this.getPolicyNum(); //获取国家级、省级、市级政策数量
    this.mapInit(); // 初始地图
  },
  mounted() {
    this.getInitialData(); // 初始化业务数据
    this.getAllList("510600000000");
  },
  methods: {
    // 获取政策数量
    getPolicyNum() {
      let url = "/dev-api/screen/getZcNumByAreaId";
      this.$httpApi.get(url, {}).then((res) => {
        let arr = res.data;
        sessionStorage.setItem("policyNumList", JSON.stringify(arr));
      });
    },
    /**
     * @description: 初始化业务数据
     * @param {*}
     * @return {*}
     */
    async getInitialData() {
      let areacode = this.currentArea.id ? this.currentArea.id.toString() : "";
      // console.log("---------------------------", areacode)
      let areacodeLen = areacode.length;
      if (areacodeLen === 6) {
        // 区级
        areacode = "";
      }
      // console.log("---------------------------处理后的areacode>>>", areacode)
      let params = {
        areacode: areacode, // 区划编码
        starttime: this.currentTime[0],
        endtime: this.currentTime[1],
      };
      // console.log("---------------------------传参>>>", params)
      this.getFocus(params); // 重点园区+重点企业
      this.getsqDirect(params); // 诉求直达
      this.getPolicyDirect(params); // 政策直达
      // this.getSqServiceHeat(params);          // 诉求服务热度
      // this.getSqEnterpriseAnalysis(params);   // 诉求企业分析
      // this.getZcApplyHeat(params);            // 政策申请热度
      // this.getZcToEffect(params);             // 政策直达成效
    },
    /**
     * @description: 获取重点园区 + 重点企业
     * @param {*}
     * @return {*}
     */
    getFocus(params) {
      getZdQyYqNum(params).then((res) => {
        let target;
        // console.log("获取重点园区 + 重点企业接收>>>", res)
        if (res === 500) {
          return;
        } else {
          target = cleanFocus(res.zdQyYqDto);
        }
        // console.log("获取重点园区 + 重点企业处理>>>", target)
        this.$store.commit("upzdQyYqDto", target);
      });
    },
    /**
     * @description: 诉求直达  this.$store.commit("upsqDirect", cleansqywNumDto( res.sqywNumDto ));      // 诉求直达
     * @param {*}
     * @return {*}
     */
    getsqDirect(params) {
      getSqywNum(params).then((res) => {
        let target;
        // console.log("政府直达接收>>>", res)
        if (res === 500) {
          return;
        } else {
          target = cleansqywNumDto(res.sqywNumDto, this.currentTime);
        }
        // console.log("政府直达处理>>>", target)
        this.$store.commit("upsqDirect", target);
      });
    },
    /**
     * @description: 政策直达 this.$store.commit("uppolicyDirect", cleanPolicyDirect( res.zcxmNumDto, res.zcxmYwNumDto ));    // 政策直达
     * @param {*}
     * @return {*}
     */
    getPolicyDirect(params) {
      getZcxmNum(params)
        .then((res) => {
          if (res === 500) {
            return "";
          }
          // console.log("1", res)
          return res.zcxmNumDto;
        })
        .then((zcxmNumDto) => {
          // console.log("2", zcxmNumDto);
          getZcxmYwNum(params).then((res) => {
            // 国家、省、市级政策数量数组
            let arr = sessionStorage.getItem("policyNumList");
            arr = JSON.parse(arr);
            // console.log("政策直达", params, res);
            let target;
            if (res === 500) {
              return;
            } else {
              target = cleanPolicyDirect({
                zcxmNumDto:zcxmNumDto,
                zcxmYwNumDto:res.zcxmYwNumDto,
                time:this.currentTime,
                gjnum:arr[0].num,//国家级政策数量
                provinceNum:arr[1].num,//省级政策数量
                sjnum:arr[2].num,//市级政策数量
                xjnum:arr[3].num//县级政策数量
            });
            }
            this.$store.commit("uppolicyDirect", target);
          });
        });
    },
    /**
     * @description: 获取区域信息的tree结构
     * @param {*}
     * @return {*}
     */
    async getAreaTree() {
      return new Promise((resolve) => {
        getAreaTree().then((res) => {
          // console.log("获取区域树>>>", res);
          if (res.code === 200) {
            // let target = JSON.parse(JSON.stringify(res.rows));
            // this.areaList = target;
            // this.breadcrumbList = target;
          }
        });
        resolve("成功");
      });
    },
    /**
     * @description 面包屑选中的区域
     */
    selectBreadcrumb(item, index) {
      let target = this.breadcrumbList.slice(-1)[0].children;
      for (let item of target) {
        item.isAction = false;
      }
      // console.log("漯河市>>>", item)
      this.$store.commit("upcurrentArea", item);
      this.zoomMax = 10;
      this.mapInit();
    },
    /**
     * @description 点击切换area框内容
     */
    selectArea(area) {
      // console.log("area>>>", area)
      // 1. 选中
      if (area.qhid == this.nowArea) {
        this.nowArea = 5106000000000;
        this.selectBreadcrumb();
        this.getAllList("510600000000");
      } else {
        if (this.breadcrumbList.length !== 0) {
          let target = this.breadcrumbList.slice(-1)[0].children;
          for (let item of target) {
            item.isAction = false;
          }
        }
        area.isAction = true;
        this.$store.commit("upcurrentArea", area);
        this.getAllList(area.qhid);
        this.$forceUpdate();
        // 2. 定位
        this.zoomMax = 12;
        this.mapInit(area.lat, area.lon, 11, area.qhid);
        this.nowArea = area.qhid;
      }
    },
    /**
     * @description: 地图初始化方法
     * @param {*} lat
     * @param {*} lon
     * @param {*} zoom
     * @param {*} id
     * @return {*}
     */
    mapInit(lat = 104.398651, lon = 31.127991, zoom = 10, id) {
      let _that = this;
      AMapLoader.load({
        key: "f7ffbd73c84b1fec35436dd1f223b3c2", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], //插件列表
        AMapUI: {
          // 是否加载 AMapUI，缺省不加载
          version: "1.1", // AMapUI 缺省 1.1
          plugins: [], // 需要加载的 AMapUI ui插件
        },
      })
        .then((AMap) => {
          _that.map = new AMap.Map("map", {
            center: [lat, lon],
            zoom: zoom,
            mapStyle: "amap://styles/ca4042d4e326c39f3225320a6a1bbd8c",
          });
          let trafficLayer = new AMap.TileLayer.Traffic({
            zIndex: 2,
          });
          _that.map.add(trafficLayer); //添加图层到地图
          _that.mapUIInit(id); // 初始化区块
        })
        .catch((e) => {
          console.log(e);
        });
    },
    mapUIInit(id) {
      let _that = this;
      _that.mapUI = null;
      _that.mapUI = new AMapUI.loadUI(["geo/DistrictExplorer"], function (
        DistrictExplorer
      ) {
        //启动页面
        _that.initPage(DistrictExplorer, id);
      });
    },
    initPage(DistrictExplorer, id = 510600) {
      let _that = this;
      //创建一个实例
      let districtExplorer = new DistrictExplorer({
        map: _that.map, //关联的地图实例
      });
      let adcode = id; //全国的区划编码
      districtExplorer.loadAreaNode(adcode, function (error, areaNode) {
        if (error) {
          console.error(error);
          return;
        }
        //绘制载入的区划节点
        _that.renderAreaNode(districtExplorer, areaNode);
      });
    },
    renderAreaNode(districtExplorer, areaNode) {
      let _that = this;
      //清除已有的绘制内容
      districtExplorer.clearFeaturePolygons();
      //just some colors
      let colors = [
        "#3366cc",
        "#dc3912",
        "#ff9900",
        "#109618",
        "#990099",
        "#0099c6",
        "#dd4477",
        "#66aa00",
      ];
      console.log("-------------级别", areaNode);
      //绘制子级区划
      districtExplorer.renderSubFeatures(areaNode, function (feature, i) {
        let fillColor = colors[i % colors.length];
        let strokeColor = colors[colors.length - 1 - (i % colors.length)];
        return {
          cursor: "default",
          bubble: true,
          strokeColor: strokeColor, //线颜色
          strokeOpacity: 1, //线透明度
          strokeWeight: 1, //线宽
          fillColor: fillColor, //填充色
          fillOpacity: 0.35, //填充透明度
        };
      });
      //绘制父级区划，仅用黑色描边
      districtExplorer.renderParentFeature(areaNode, {
        cursor: "default",
        bubble: true,
        strokeColor: "#ff6a00", //线颜色
        fillColor: areaNode.adcode === 411100 ? null : "#ff9900",
        fillOpacity: 0.1, //填充透明度
        strokeWeight: 2, //线宽
      });
      //更新地图视野以适合区划面
      _that.map.setFitView(
        districtExplorer.getAllFeaturePolygons(), // 覆盖物数组
        false, // 动画过渡到定制位置 false 是开  true 是关
        [0, 0, 0, 0], // 周围边距
        this.zoomMax // 最大zoom级别
      );
    },
    getAllList(areaid) {
      let url = "/dev-api/enterprise/baseInfo/getCategoryAndNum";
      let params = {
        areaId: areaid.toString(),
        dictType: "enterprise_attributes",
      };
      this.$httpApi
        .post(url, params)
        .then((r) => {
          this.showTracingArr = r.data;
        })
        .catch((e) => {
          this.$message.error("获取数据错误");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.analysis-wrap {
  position: relative;
  width: 100%;
  height: 800px;
}
</style>
<style lang="scss">
.amap-logo {
  display: none; //去掉高德地图logo
  opacity: 0 !important;
}
.left-content1 {
  position: absolute;
  padding: 25px 25px;
  width: 300px;
  height: auto;
  bottom: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.right-content1 table {
  border-collapse: collapse;
  border-color: #39aecd;
  border-width: 2px;
  border-style: solid;
  color: white;
  font-size: 25px;
  width: 250px;
}
.right-content1 tr {
  border: 2px solid #37aece;
  height: 50px;
  width: 100%;
}
.td-select {
  padding: 5px;
  background: #21aaf2;
  text-align: center;
  border-right: 2px solid #37aece;
}
.td-select-value {
  padding: 5px;
  background: #21aaf2;
  text-align: center;
  border-right: 2px solid #37aece;
  font-size: 28px;
  white-space: nowrap;
}
.td-name {
  padding: 5px;
  background: #1541bd;
  text-align: center;
  border-right: 2px solid #37aece;
}
.td-value {
  // height: 100%;
  line-height: 100%;
  width: 100px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
}
.td-select a {
  color: #ffcd42;
  font-size: 25px;
}
.td-select-value a {
  color: #ffcd42;
  font-size: 25px;
  white-space: nowrap;
}
.td-value a {
  color: #ffcd42;
  font-size: 25px;
}
.td-name a {
  color: #ffcd42;
  font-size: 25px;
}
.right-content1 {
  position: absolute;
  bottom: 64px;
  right: 10px;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.middleIndex {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  // background-color: #1ae7b4;
}
#map {
  position: absolute;
  width: 100%;
  height: 100%;
}
.map-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 100px transparent solid;
  border-image: linear-gradient(to right, transparent, #23b7cb) 1 10;
}
.pop {
  padding: 10px;
  flex-direction: column;
  border-radius: 10px;
  border-color: #39aecd;
  background: #11328e7f;
  border-width: 2px;
  border-style: solid;
  color: white;
  font-size: 20px;
}
.pop img {
  margin: 0 0 0 30px;
  width: 400px;
  height: 400px;
}
.pop .img-text {
  display: flex;
}
.circle1 {
  background: #fe7b7c;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-right: 10px;
}
.circle2 {
  background: #ffd461;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  margin-right: 10px;
}
.circle3 {
  margin-right: 10px;
  background: #1ae7b4;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.circle-tip {
  margin: 10px 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
}
.circle-tip img {
  margin-right: 10px;
  width: 40px;
  height: 40px;
}
.pop-value {
  max-width: 500px;
}
.pop-name {
  min-width: 200px;
  text-align: right;
}
.pop-row {
  display: flex;
}
</style>
<style lang="scss" scoped>
.middle-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
}
.map-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
.map-left {
  // background: #fff;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: -10px;
}
.map-header,
.map-left,
.map-right {
  position: absolute;
}
.ener-con {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1005;
  background: $bc-second;
}
/* 地图标题 */
.map-header {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 1000px;
  height: 90px;
  line-height: 90px;
  font-family: FZZhengHeiS-EB-GB;
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 8px;
  text-align: center;
  background: url("../../assets/images/header_banner.png") center / 100% 100%
    no-repeat;
}
/* 地图左边 + 右边 */
.map-left,
.map-right {
  top: 16%;
  height: calc(100% - 12%);
}
/* 地图左边 */
.map-left {
  left: 20px;
  width: 280px;
}
/* 地图右边 */
.map-right {
  color: #fff;
  right: 20px;
  width: 280px;
  padding-bottom: 20px;
}
/* 搜索 */
.map-search {
  position: relative;
  // height: 36px;
  margin-bottom: 10px;
  display: flex;
  .shouqi {
    position: relative;
    width: 56%;
    height: 36px;
    line-height: 36px;
    color: #3d91ff;
    font-weight: 800;
    text-align: center;
    // background: rgba($col-theme, .7);
    background: #fff;
    margin-bottom: -9px;
  }
  .shouqi::after {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    content: "";
    width: 0;
    height: 0;
    border: 18px solid;
    border-color: transparent transparent #fff #fff;
  }
}
.search-input-wrap {
  position: relative;
  padding: 2px;
  width: 160px;
  height: 40px;
  margin-right: 10px;
}
.search-btn-wrap {
  position: relative;
  padding: 2px;
  width: 50px;
  height: 40px;
  cursor: pointer;
}
.search-input {
  width: 100%;
  height: 100%;
  // background: rgba($col-theme, 1);
  background: #0067b6;
  font-size: 14px;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  border: 0;
}
.search-btn {
  // width: 60px;
  padding: 0 4px;
  height: 100%;
  margin-right: 2px;
  text-align: center;
  background: rgba(#0092de, 0.53);
  border: 1px solid rgba(#05fbfe, 0.5);
  i {
    color: #06f7fc;
  }
}
/* 区域选择 */
.left-area {
  font-size: 14px;
  .area-title {
    position: relative;
    width: 56%;
    height: 36px;
    line-height: 36px;
    color: #3d91ff;
    font-weight: 800;
    text-align: center;
    // background: rgba($col-theme, .7);
    background: #fff;
  }
  .area-title::after {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    content: "";
    width: 0;
    height: 0;
    border: 18px solid;
    border-color: transparent transparent #f4f4f4 #f4f4f4;
  }
  .area-name {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    background: #fff;
    border-top: 1px solid #f4f4f4;
  }
  .area-list {
    width: 100%;
    height: 40px;
    background: #3d91ff;
    line-height: 40px;
    padding-left: 10px;
    color: #fff;
  }
  .area-content {
    // border: 1px solid rgba(#06f7fc, 0.2);
    // background: rgba($col-theme, 1);
    background: #ebf4ff;
  }
}
.grid-wrap {
  padding: 10px;
}
.grid-content {
  // padding-bottom: 10px;
  text-overflow: ellipsis; // 用省略号
  overflow: hidden; // 超出部分隐藏
  white-space: nowrap; // 文本不换行，这样超出一行的部分被截取，显示...
  cursor: pointer;
  line-height: 32px;
  text-align: center;
  color: #333;
  &:hover {
    // background: $col-border;
    background: #ebf4ff;
    color: $col-border;
  }
}
.grid-isAction {
  font-weight: 700;
  background: $col-border;
  color: #fff;
}
.area-show {
}
/* 企业 + 园区 + 服企中心 展示 */
.tracing-wrap {
  position: relative;
  width: 100%;
  margin-top: 16px;
  padding: 2px;
}
.top-area {
  padding: 8px;
  font-weight: 700;
  color: #12f5ff;
  letter-spacing: 1px;
}
.tracing-content {
  width: 100%;
  // background: rgba($col-theme, 1);
  // background: #0067b6;
  background: #fff;
  font-size: 14px;
  max-height: 40vh;
  overflow: hidden;
  overflow-y: auto;
}
.tracing-content::-webkit-scrollbar {
  display: none;
}
.tracing-title {
  font-weight: 700;
  // padding: 8px 0;
  // border: 1px solid $col-border-middle;
}
.left-title,
.right-title,
.left-item,
.right-item {
  height: 100%;
  line-height: 30px;
  // padding: 5px 4px;
  text-align: center;
  // border-right: 1px solid $col-border-middle;
  border-right: 1px solid #f4f4f4;
  color: #737373;
  font-weight: 600;
}
.tracing-item {
  color: #fff;
  // border: 1px solid $col-border-middle;
  border: 1px solid #e8e8e8;
  border-right: 0;
  border-bottom: 0;
  :hover {
    font-weight: 700;
    // background: $col-border;
    background: #fff;
  }
}
/*地图弹出层样式*/
.ol-popup {
  position: absolute;
  border: 1px #37accd solid;
  background: linear-gradient(
    rgba(0, 118, 255, 0.5) 0%,
    rgba(21, 67, 191, 0.5) 0%,
    #1543bf 100%
  );
  padding: 15px;
  border-radius: 10px;
  bottom: 24px;
  left: -50px;
  z-index: 99999;
}
.ol-popup:after,
.ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
  cursor: pointer;
}
.ol-popup-content {
  width: 300px;
  color: white;
}
/*.ol-popup-closer:after {*/
/*  content: "x";*/
/*  color: black;*/
/*}*/
.block-item {
  width: 140px;
  height: 90px;
  margin-right: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;
}
.block-item1 {
  background: #69a830;
}
.block-item2 {
  background: #0095cb;
}
</style>
