/*
 * @Description: 
 * @Author: CZ
 * @Date: 2021-06-17 21:27:52
 * @Editors: CZ
 * @LastEditTime: 2021-06-24 21:09:49
 */
import request from '@/utils/request';

/**
 * @description:  获取区域的数据： 宝安区，街道，社区
 * @param {*} params 
 * @return {*}
 */
export function getAreaTree() {
  return request({
    url: '/dev-api/cfginfo/region/cascaderTreeList',
    method: 'get', 
  })
}
